import { Spinner } from "@kanpla/ui";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocalstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "../components/contextProvider";
import LandingWrapper from "../components/LandingWrapper";
import LoginContent from "../components/login/LoginContent";

const Login = () => {
  const { t } = useTranslation(["translation"]);

  const { auth } = useContainer(AppContext);

  const [isLoggedIn, setIsLoggedIn] = useLocalstorageState("user_logged_in");

  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    setTimeout(() => setTimedOut(true), 5000);
    return () => setTimedOut(false);
  }, []);

  return (
    <LandingWrapper>
      <div className="w-full text-center my-auto">
        {isLoggedIn ? (
          <div className="flex flex-col h-100 w-100 justify-center items-center text-primary-main">
            <Spinner useCurrentColor size="medium" />
            <div className="mt-8">
              {timedOut && (
                <>
                  <p className="text-text-secondary text-sm">
                    {t("translation:problems-with-login")}
                  </p>
                  <Button
                    type="primary"
                    className="text-text-primary mt-2"
                    onClick={() => {
                      auth.signOut();
                      setIsLoggedIn(false);
                    }}
                  >
                    {t("translation:log-out")}
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          <LoginContent />
        )}
      </div>
    </LandingWrapper>
  );
};

export default Login;
